<div class="absolute py-10 px-15 z-50 right-0 top-0" style="width: 100%; height: 100%; background-color: #ffff">
    <div class="">
        <h1 class="font-bold text-3xl">Recompra de títulos</h1>
        <p>
            Configure abaixo as taxas que irá cobrar de mora e multa para os recebíveis vencidos. Os valores serão
            calculados na listagem abaixo, onde você pode selecionar quais títulos deseja recomprar nesta operação.
        </p>
    </div>

    <div class="my-5">
        <div class="flex justify-between items-center">
            <form class="flex gap-4 items-end" [formGroup]="repurchaseFeesForm">
                <mat-form-field>
                    <mat-label>Mora</mat-label>
                    <input
                        matInput
                        formControlName="lateFee"
                        placeholder="0,00"
                        inputmode="numeric"
                        [options]="{
                            prefix: '',
                            thousands: '.',
                            decimal: ',',
                            align: 'left',
                            max: 100
                        }"
                        currencyMask
                    />
                    <mat-icon matSuffix>percent</mat-icon>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Multa</mat-label>
                    <input
                        matInput
                        formControlName="fine"
                        placeholder="0,00"
                        inputmode="numeric"
                        [options]="{
                            prefix: '',
                            thousands: '.',
                            decimal: ',',
                            align: 'left',
                            max: 100
                        }"
                        currencyMask
                    />
                    <mat-icon matSuffix>percent</mat-icon>
                </mat-form-field>

                <button
                    class="flex w-full items-center justify-center rounded-full border border-gray-500 px-6 py-3 md:w-auto md:justify-start gap-2"
                    type="solid"
                    (click)="openFilterModal()"
                >
                    <mat-icon [svgIcon]="'heroicons_outline:filter'" class="icon-size-4"></mat-icon>
                    Filtros
                </button>

                <button
                    *ngIf="isFilterApplied"
                    class="flex w-full items-center justify-center rounded-full border border-gray-500 px-6 py-3 md:w-auto md:justify-start gap-2"
                    type="solid"
                    (click)="resetFilters()"
                >
                    Limpar filtros
                </button>
            </form>

            <div class="flex items-center gap-10">
                <div>
                    <p class="text-[#1E293B] text-sm font-bold flex items-center gap-2">
                        Total de Mora e Multa
                        <mat-icon
                            [svgIcon]="'heroicons_solid:question-mark-circle'"
                            class="icon-size-3"
                            matTooltip="Cálculo total de multa e mora"
                        ></mat-icon>
                    </p>
                    <p>{{ feeFineSum | currency : "R$ " }}</p>
                </div>

                <div>
                    <p class="text-[#1E293B] text-sm font-bold flex items-center gap-2">
                        Valor total de recompra
                        <mat-icon
                            [svgIcon]="'heroicons_solid:question-mark-circle'"
                            class="icon-size-3"
                            matTooltip="Valor total da recompra de títulos"
                        ></mat-icon>
                    </p>
                    <p>{{ payablesSum | currency : "R$ " }}</p>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="isLoading" class="flex justify-center items-center w-full h-100">
        <mat-progress-spinner [diameter]="24" [mode]="'indeterminate'"></mat-progress-spinner>
    </div>

    <div *ngIf="!isLoading">
        <p class="text-gray-600 text-sm mb-2">
            {{ selectedPayables.length }} de {{ allUnoperatedPayablesResponse.meta.total }} selecionados
        </p>

        <div cdkScrollable class="overflow-x-auto sm:overflow-y-auto max-h-90">
            <table mat-table [dataSource]="allUnoperatedPayables" class="w-full table-fixed bg-transparent">
                <ng-container matColumnDef="check">
                    <th mat-header-cell *matHeaderCellDef disableClear id="check" class="font-bold custom-width"></th>
                    <td mat-cell *matCellDef="let payable">
                        <input type="checkbox" [checked]="checkIfSelected(payable)" (change)="selectPayable(payable)" />
                    </td>
                </ng-container>

                <ng-container matColumnDef="payableNumber">
                    <th mat-header-cell *matHeaderCellDef disableClear id="payableNumber" class="font-bold">
                        Número de Título
                    </th>
                    <td mat-cell *matCellDef="let payable">
                        {{ payable.payableNumber }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="payerName">
                    <th mat-header-cell *matHeaderCellDef disableClear id="payerName" class="font-bold">Sacado</th>
                    <td mat-cell *matCellDef="let payable">
                        {{ payable.payer.companyName | titlecase }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef disableClear id="value" class="font-bold">Valor em aberto</th>
                    <td mat-cell *matCellDef="let payable">
                        {{ payable.value | currency : "R$ " }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="repurchaseValue">
                    <th mat-header-cell *matHeaderCellDef disableClear id="repurchaseValue" class="font-bold">
                        Valor a ser recomprado
                        <mat-icon
                            *ngIf="noHasBankmeUser"
                            [svgIcon]="'heroicons_solid:question-mark-circle'"
                            class="icon-size-3"
                            matTooltip="Em breve você poderá alterar o valor a ser recomprado"
                        ></mat-icon>
                    </th>
                    <td mat-cell *matCellDef="let payable">
                        <mat-form-field
                            style="margin-bottom: 0 !important; padding-bottom: 0 !important"
                            [ngClass]="{'border-red-500': errors?.[payable.id]?.hasError}"
                        >
                            <input
                                matInput
                                currencyFormatter
                                currencyMask
                                max="{{ payable.repurchaseValue }}"
                                [options]="{ prefix: 'R$ ', suffix: '', thousands: '.', decimal: ',', align: 'left' }"
                                value="{{ payable.repurchaseValue }}"
                                name="repurchase-value-{{ payable.id }}"
                                [ngModel]="payable.repurchaseValue"
                                (ngModelChange)="onRepurchaseValueChange($event, payable)"
                                id="repurchase-value-{{ payable.id }}"
                                [disabled]="noHasBankmeUser"
                            />
                        </mat-form-field>
                    </td>
                </ng-container>

                <ng-container matColumnDef="dueDate">
                    <th mat-header-cell *matHeaderCellDef disableClear id="dueDate" class="font-bold">Vencimento</th>
                    <td mat-cell *matCellDef="let payable">
                        {{ formatDateIso(payable.dueDate) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="timeUntilDueDate">
                    <th mat-header-cell *matHeaderCellDef disableClear id="timeUntilDueDate" class="font-bold">
                        Tempo até o vencimento
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let payable"
                        [ngClass]="{
                            'text-red-500': formatTimeUntilDueDate(payable.dueDate).includes('Vencido')
                        }"
                    >
                        {{ formatTimeUntilDueDate(payable.dueDate) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="payableType">
                    <th mat-header-cell *matHeaderCellDef disableClear id="payableType" class="font-bold text-center">
                        Tipo do recebivel
                    </th>
                    <td mat-cell *matCellDef="let payable" class="text-center">
                        {{ payable.payableType | titlecase }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="lateFee">
                    <th mat-header-cell *matHeaderCellDef disableClear id="lateFee" class="font-bold pl-3">Mora</th>
                    <td mat-cell *matCellDef="let payable" class="pl-3">
                        {{ (checkIfSelected(payable) ? calculateLateFee(payable) : "") | currency : "R$" }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="fine">
                    <th mat-header-cell *matHeaderCellDef disableClear id="fine" class="font-bold pl-3">Multa</th>
                    <td mat-cell *matCellDef="let payable" class="pl-3">
                        {{ (checkIfSelected(payable) ? calculateFine(payable) : "") | currency : "R$" }}
                    </td>
                </ng-container>

                <tr
                    mat-header-row
                    *matHeaderRowDef="unoperatedPayablesTableColumns; sticky: true"
                    class="bg-gray-200 text-sm rounded-2xl"
                ></tr>
                <tr
                    mat-row
                    *matRowDef="let payable; columns: unoperatedPayablesTableColumns"
                    class="h-18 hover:bg-hover"
                    [ngClass]="{ 'bg-hover': checkIfSelected(payable) }"
                ></tr>
            </table>
        </div>

        <nav class="py-2 flex justify-between border-t-1 border-gray-200 pt-2">
            <p class="text-gray-600 text-sm">
                Página {{ allUnoperatedPayablesResponse.meta.currentPage }} de {{ totalPages }}
            </p>
            <div>
                <mat-icon class="cursor-pointer icon-size-5 hover:text-black" (click)="firstPage()">
                    first_page
                </mat-icon>
                <mat-icon class="cursor-pointer icon-size-5 hover:text-black" (click)="previousPage()">
                    chevron_left
                </mat-icon>
                <mat-icon class="cursor-pointer icon-size-5 hover:text-black" (click)="nextPage()">
                    chevron_right
                </mat-icon>
                <mat-icon class="cursor-pointer icon-size-5 hover:text-black" (click)="lastPage()">last_page</mat-icon>
            </div>
        </nav>

        <div class="flex justify-center gap-32 w-full h-auto bg-white mt-auto">
            <button
                class="text-primary-500 font-bold py-2 px-6 hover:bg-gray-200 transition-all duration-300"
                (click)="close()"
            >
                Cancelar
            </button>

            <button
                class="bg-primary-500 text-white font-bold rounded-full py-2 px-6 hover:bg-primary-400 transition-all duration-300"
                (click)="select()"
            >
                Salvar
            </button>
        </div>
    </div>
</div>
